// src/pages/Kiyaku.tsx
import React from 'react';

const Kiyaku: React.FC = () => {
  return (
    <div>
      <h1>利用規約</h1>
      
      <h2>1. はじめに</h2>
      <p>本規約は、本サービスを提供する[サービス名]（以下「当社」という）が、本サービスの利用者に提供するサービスの利用に関する条件を定めるものです。登録ユーザーの皆様には、本規約に従い本サービスをご利用いただきます。</p>
      
      <h2>2. アカウント</h2>
      <p>ユーザーは、本サービスを利用するためにアカウントを作成する際、真実、正確、かつ最新の情報を提供するものとします。また、ユーザーは自己のアカウント情報を適切に保管する責任を持ちます。</p>

      <h2>3. 禁止行為</h2>
      <ul>
        <li>法令または公序良俗に違反する行為</li>
        <li>犯罪行為に関連する行為</li>
        <li>当社、他のユーザー、または第三者の権利を侵害する行為</li>
      </ul>

      <h2>4. 著作権</h2>
      <p>本サービス上で提供されるコンテンツの著作権は当社または当該コンテンツの提供者に帰属します。ユーザーは、当社の明示的な同意がない限り、これらのコンテンツを無断で複製、配布することはできません。</p>

      <h2>5. 利用制限および登録抹消</h2>
      <p>以下の場合、当社はユーザーの行為が本規約の各条項に違反したと判断する場合、ユーザーに通知することなく、本サービスの全てまたは一部の利用を制限、またはユーザーとしての登録を抹消することができるものとします。</p>
      <ul>
        <li>本規約のいずれかの条項に違反した場合</li>
        <li>登録情報に虚偽の事実があることが判明した場合</li>
      </ul>

      <h2>6. 免責事項</h2>
      <p>当社は、本サービスに関して、その完全性、正確性、確実性、有用性などに関して、いかなる保証も行うものではありません。ユーザーが本サービスを利用して発生した損害に対して、当社は一切の責任を負いません。</p>

      <h2>7. 利用規約の変更</h2>
      <p>当社は、必要と判断した場合には、いつでも本利用規約の内容を変更することができます。変更後の利用規約は、本サービス上での掲載または告知をもって、その効力を生じるものとします。</p>

      <p>以上</p>
    </div>
  );
}

export default Kiyaku;
