// src/Register.tsx
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { initializeApp } from 'firebase/app'; // <-- Firebase initialization import
import { getDatabase, ref, set } from 'firebase/database'; // Firebase Realtime Database imports
import { firebaseConfig } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import './Register.css';

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

const auth = getAuth(app); // Pass the initialized app to getAuth
const db = getDatabase(app); // Pass the initialized app to getDatabase

const Register: React.FC = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        return updateProfile(user, { displayName: username });
      })
      .then(() => {
        const userRef = ref(db, `users/${username}`);
        return set(userRef, {
          username: username,
          email: email
        });
      })
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Error during registration:', error);
      });
  };

  return (
    <div className="login-container">
      <h1>新規登録</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="username">ユーザー名:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" onClick={handleRegister}>新規登録</button>
      </form>
    </div>
  );
};

export default Register;
