// src/components/MenuBar.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './MenuBar.css';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

const MenuBar: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // ハンバーガーメニューの状態

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setIsLoggedIn(false);
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  return (
    <nav className="menu-bar">
      <div className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`menu-list ${isMenuOpen ? 'open' : ''}`}>
        <li className="menu-item">
          <Link to="/" className="menu-link">ホーム</Link>
        </li>
        <li className="menu-item">
          <Link to="/appdetail" className="menu-link">サービス一覧</Link>
        </li>
        <li className="menu-item">
          <Link to="/dashboard" className="menu-link">登録</Link>
        </li>
        <li className="menu-item">
          <Link to="/support" className="menu-link">お問い合わせ</Link>
        </li>
        <li className="menu-item">
          <Link to="/privacy" className="menu-link">プライバシーポリシー</Link>
        </li>
        <li className="menu-item">
          <Link to="/kiyaku" className="menu-link">利用規約</Link>
        </li>
        {isLoggedIn && (
          <li className="menu-item">
            <Link to="/" className="menu-link" onClick={handleLogout}>ログアウト</Link>
          </li>
        )}
        {!isLoggedIn && (
          <>
            <li className="menu-item">
              <Link to="/register" className="menu-link">新規登録</Link>
            </li>
            <li className="menu-item">
              <Link to="/login" className="menu-link">ログイン</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default MenuBar;
