// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Pricing.css */
.pricing-section {
    margin: 40px 0;
  }
  
  .plan {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
  }
  
  .plan h3 {
    margin-bottom: 10px;
  }
  
  /* 色の指定 */
  .plan.free {
    background-color: #B0E0E6; /* 水色 */
  }
  
  .plan.standard {
    background-color: #f5f5a2; /* 黄色 */
  }
  
  .plan.premium {
    background-color: #FFC0CB; /* ピンク色 */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Pricing.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA,SAAS;EACT;IACE,yBAAyB,EAAE,OAAO;EACpC;;EAEA;IACE,yBAAyB,EAAE,OAAO;EACpC;;EAEA;IACE,yBAAyB,EAAE,SAAS;EACtC","sourcesContent":["/* src/Pricing.css */\n.pricing-section {\n    margin: 40px 0;\n  }\n  \n  .plan {\n    margin-bottom: 20px;\n    padding: 20px;\n    border-radius: 5px;\n  }\n  \n  .plan h3 {\n    margin-bottom: 10px;\n  }\n  \n  /* 色の指定 */\n  .plan.free {\n    background-color: #B0E0E6; /* 水色 */\n  }\n  \n  .plan.standard {\n    background-color: #f5f5a2; /* 黄色 */\n  }\n  \n  .plan.premium {\n    background-color: #FFC0CB; /* ピンク色 */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
