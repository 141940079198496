// src/pages/AppRegistration.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AppRegistration.module.css';
import { useApps } from '../contexts/AppContext';
import { getDatabase, ref, push } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const AppRegistration: React.FC = () => {
  const [appName, setAppName] = useState('');
  const [appImage, setAppImage] = useState<File | null>(null);
  const [appDescription, setAppDescription] = useState('');
  const [appLink, setAppLink] = useState('');
  const navigate = useNavigate();

  // 新しいステート: ローディング
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setAppImage(file);
    }
  };

  const uploadImageToFirebaseStorage = async (file: File) => {
    const storage = getStorage();
    const storageReference = storageRef(storage, 'images/' + file.name);
    const uploadTask = uploadBytesResumable(storageReference, file);

    return new Promise<string>((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          // 進捗を表示するロジックは省略
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleAddApp = async () => {
    setIsLoading(true);

    let imageUrl: string | null = null;

    if (appImage) {
      imageUrl = await uploadImageToFirebaseStorage(appImage);
    }

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('ユーザーがログインしていません。');
      setIsLoading(false);
      return;
    }

    const newApp = {
      userId: user.uid,
      name: appName,
      image: imageUrl,
      description: appDescription,
      link: appLink,
    };

    const db = getDatabase();
    const appsRef = ref(db, 'apps');
    try {
      await push(appsRef, newApp);
      console.log('Data saved successfully to Realtime Database.');
    } catch (error) {
      console.error('Error saving data to Realtime Database:', error);
    }

    console.log('New App:', newApp);

    setAppName('');
    setAppImage(null);
    setAppDescription('');
    setAppLink('');

    setIsLoading(false);
    navigate('/dashboard');
};

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>登録</h1>

      <div className={styles.inputGroup}>
        <label className={styles.label}>
          サービス名:
          <input
            value={appName}
            onChange={(e) => setAppName(e.target.value)}
            className={styles.input}
            disabled={isLoading}
          />
        </label>
      </div>

      <div className={styles.inputGroup}>
        <label className={styles.label}>
          画像:
          <input
            type="file"
            onChange={handleImageChange}
            className={styles.input}
            disabled={isLoading}
          />
          {appImage && <img src={URL.createObjectURL(appImage)} alt="プレビュー" width="100" />}
        </label>
      </div>

      <div className={styles.inputGroup}>
        <label className={styles.label}>
          説明:
          <textarea
            value={appDescription}
            onChange={(e) => setAppDescription(e.target.value)}
            className={styles.textarea}
            disabled={isLoading}
          />
        </label>
      </div>

      <div className={styles.inputGroup}>
        <label className={styles.label}>
          サービスのリンク:
          <input
            value={appLink}
            onChange={(e) => setAppLink(e.target.value)}
            className={styles.input}
            disabled={isLoading}
          />
        </label>
      </div>

      <button className={styles.button} onClick={handleAddApp} disabled={isLoading}>
        {isLoading ? '登録中...' : '登録する'}
      </button>
    </div>
  );
}

export default AppRegistration;
