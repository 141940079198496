// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blueButton {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 8px 15px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  
    /* カーソルをホバーしたときのスタイル */
    &:hover {
      background-color: #0056b3;
      text-decoration: none; /* テキストの下線を削除 */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,iCAAiC;;IAEjC,sBAAsB;IACtB;MACE,yBAAyB;MACzB,qBAAqB,EAAE,eAAe;IACxC;EACF","sourcesContent":[".blueButton {\n    display: inline-block;\n    background-color: #007bff;\n    color: white;\n    padding: 8px 15px;\n    border-radius: 5px;\n    text-decoration: none;\n    transition: background-color 0.3s;\n  \n    /* カーソルをホバーしたときのスタイル */\n    &:hover {\n      background-color: #0056b3;\n      text-decoration: none; /* テキストの下線を削除 */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
