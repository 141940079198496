// src/AppDetail.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Link } from 'react-router-dom';
import './AppListDetail.css';

type AppDetails = {
  id: string; 
  name: string;
  description: string;
  downloadLink: string;
  officialWebsite: string;
  image: string;
  link: string;
};

const AppListDetail: React.FC = () => {
  const { appId } = useParams<{ appId?: string }>();
  const [apps, setApps] = useState<AppDetails[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const itemsPerPage = 10;

  useEffect(() => {
    const db = getDatabase();
    const appsRef = ref(db, 'apps');

    const unsubscribe = onValue(appsRef, (snapshot) => {
      const appsData = snapshot.val();
      const appsArray = Object.keys(appsData).map((key) => ({
        ...appsData[key],
        id: key,
      }));

      setApps(appsArray);
    });

    return () => unsubscribe();

  }, []);

  const maxPages = Math.ceil(apps.length / itemsPerPage);

  const appDetail = appId ? apps.find(app => app.id === appId) : null;

  if (!apps.length) return <p>Loading...</p>;

  return (
    <div>
      {appDetail ? (
        <div className="app-detail">
          <h2>{appDetail.name}</h2>  {/* サービス名 */}
          <img src={appDetail.image} alt={appDetail.name} className="app-image" /> {/* 画像 */}
          <p>{appDetail.description}</p>  {/* 詳細 */}
          <a href={appDetail?.link}>リンク</a>
        </div>
      ) : (
        <div>
          <div className="app-list">
            {apps.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((app) => (
              <Link key={app.id} to={`/appdetail/${app.id}`} className="app-item">
                <img src={app.image} alt={app.name} className="app-image" />
                <span>{app.name}</span>
              </Link>
            ))}
          </div>
          {currentPage < maxPages && <button onClick={() => setCurrentPage(currentPage + 1)}>次へ</button>}
        </div>
      )}
    </div>
  );
}

export default AppListDetail;
