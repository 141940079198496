// src/pages/Pricing.tsx
import React from 'react';
import './Pricing.css';

const Pricing: React.FC = () => {
  return (
    <div>
      <h1>Pricing Plans</h1>
      
      <div className="pricing-section">
        <div className="plan free">
          <h3>無料プラン</h3>
          <p>バズバズにサービスの紹介ページを掲載</p>
        </div>

        <div className="plan standard">
          <h3>スタンダードプラン (1000円)</h3>
          <p>無料プラン＋各種SNSで宣伝致します</p>
        </div>

        <div className="plan premium">
          <h3>プレミアムプラン</h3>
          <p>スタンダードプラン＋記事(1000文字以上)や動画での紹介＋複数のユーザーからのフィードバック</p>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
