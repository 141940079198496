// src/pages/AppContext.tsx
import React, { createContext, useContext, useState } from 'react';

type AppOrService = {
    id: number;
    name: string;
    image: string | null; // ここを変更
    description: string;
    link: string;
  };

type AppContextType = {
  apps: AppOrService[];
  addApp: (app: Omit<AppOrService, 'id'>) => void;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [apps, setApps] = useState<AppOrService[]>([]);

  const addApp = (app: Omit<AppOrService, 'id'>) => {
    const newApp = { ...app, id: Date.now() };
    setApps(prevApps => [...prevApps, newApp]);
  };

  return (
    <AppContext.Provider value={{ apps, addApp }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApps = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApps must be used within an AppProvider");
  }
  return context;
};
