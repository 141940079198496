// src/pages/Home.tsx
import React from 'react';
import './Home.css';

const Home: React.FC = () => {
  return (
    <div>
      <h1>バズバズ(α版)</h1>
      <h3>webサービスやアプリを宣伝してバズるのをサポートするプラットフォーム</h3>

      <h2>~バズバズの特色~</h2>
      <li>バズバズにサービスの概要を掲載</li>
      <li>各種SNS、メルマガや公式ラインで宣伝</li>
      <li>プロモーション動画や記事を作成(予定)</li>
      <li>専用のPRチームがバズるまでサポート(予定)</li>
    </div>
  );
}

export default Home;
