// src/pages/Privacy.tsx
import React from 'react';

const Privacy: React.FC = () => {
  return (
    <div>
      <h1>プライバシーポリシー</h1>
      
      <h2>1. 個人情報の収集について</h2>
      <p>当サイトは、ユーザー登録やお問い合わせの際に、名前、メールアドレス、電話番号などの個人情報を収集する場合があります。収集した個人情報は、お問い合わせに対する回答や必要な情報を電子メールなどで送信する目的以外で使用することはありません。</p>
      
      <h2>2. 個人情報の第三者への開示</h2>
      <p>当サイトは、個人情報を適切に管理し、次のいずれかの場合を除いて第三者に開示することはありません。</p>
      <ul>
        <li>本人の許可がある場合</li>
        <li>法令等への協力のため、開示が必要となる場合</li>
      </ul>

      <h2>3. 個人情報の安全対策</h2>
      <p>当サイトは、個人情報の漏洩、紛失、または毀損を防止するための適切な対策を実施しております。</p>

      <h2>4. ログファイルについて</h2>
      <p>当サイトでは、サイトの利用状況を分析するために、IPアドレス、ブラウザの種類、ブラウザの言語などの情報を収集しています。これらの情報は、利用状況の分析や適切な広告の配信などのために利用されます。</p>

      <h2>5. アクセス解析ツールについて</h2>
      <p>当サイトでは、サイトの改善やサイトの利用状況を調査するために、Google Analyticsを利用しています。</p>

      <h2>6. 免責事項</h2>
      <p>当サイトからリンクされている外部サイトの内容やサービスに対して、当サイトは一切の責任を負いません。また、当サイトの利用により生じた結果についても、一切の責任を負いません。</p>

      <h2>7. プライバシーポリシーの変更について</h2>
      <p>当サイトは、必要に応じて、本ポリシーの内容を変更することがあります。変更した場合には、変更後のポリシーの内容をこのページに掲載します。</p>

      <p>以上</p>
    </div>
  );
}

export default Privacy;
