// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppRegistration_container__Mpmyf {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .AppRegistration_title__rfipf {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .AppRegistration_inputGroup__9Oj5\\+ {
    margin: 10px 0;
    width: 100%;
    max-width: 400px;
  }
  
  .AppRegistration_label__KF4nq {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .AppRegistration_input__49RWF, .AppRegistration_textarea__Hm87z {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .AppRegistration_textarea__Hm87z {
    resize: vertical;
    height: 100px;
  }
  
  .AppRegistration_button__thm\\+P {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 15px;
  }
  
  .AppRegistration_button__thm\\+P:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/AppRegistration.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;IACjC,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n  }\n  \n  .title {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .inputGroup {\n    margin: 10px 0;\n    width: 100%;\n    max-width: 400px;\n  }\n  \n  .label {\n    display: block;\n    margin-bottom: 10px;\n    font-weight: bold;\n  }\n  \n  .input, .textarea {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    margin-top: 5px;\n  }\n  \n  .textarea {\n    resize: vertical;\n    height: 100px;\n  }\n  \n  .button {\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.2s;\n    margin-top: 15px;\n  }\n  \n  .button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AppRegistration_container__Mpmyf`,
	"title": `AppRegistration_title__rfipf`,
	"inputGroup": `AppRegistration_inputGroup__9Oj5+`,
	"label": `AppRegistration_label__KF4nq`,
	"input": `AppRegistration_input__49RWF`,
	"textarea": `AppRegistration_textarea__Hm87z`,
	"button": `AppRegistration_button__thm+P`
};
export default ___CSS_LOADER_EXPORT___;
