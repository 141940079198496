// src/pages/Login.tsx
import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';  
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { database, firebaseConfig } from '../firebaseConfig';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);  // Start the loading state
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in successfully.');
      navigate('/');
    } catch (error) {
      console.error('Error logging in:', error);
      if (error instanceof Error) {
        setError(error.message);  // Set the error message if it's an instance of Error
      } else {
        setError('ログインに失敗しました。');  // Set a default error message
      }
    } finally {
      setLoading(false);  // End the loading state
    }
  };

  return (
    <div className="login-container">
      <h1>ログイン</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        {error && <p className="error-message">{error}</p>} {/* Display the error message */}
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" onClick={handleLogin} disabled={loading}>
          {loading ? '読み込み中...' : 'ログイン'}
        </button>
      </form>
    </div>
  );
};

export default Login;
