import React from 'react';

const Support: React.FC = () => {
  return (
    <div>
      <h1>Support & Contact</h1>
      <p>お問い合わせは以下までお願いいたします。</p>
      <p>ys.work07[at]gmail.com</p>
    </div>
  );
}

export default Support;


