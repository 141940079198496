// src/pages/Dashboard.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import './Dashboard.css';

type AppOrService = {
  id: number;
  name: string;
  image: string;
  description: string;
  link: string;
};

const Dashboard: React.FC = () => {
  const [apps, setApps] = useState<AppOrService[]>([]);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (!user) {
      console.error('ユーザーがログインしていません。');
      return;
    }

    const db = getDatabase();
    const appsRef = ref(db, 'apps/' + user.uid);

    const unsubscribe = onValue(appsRef, (snapshot) => {
      const appsData: AppOrService[] = [];
      snapshot.forEach(childSnapshot => {
        const appData = childSnapshot.val() as AppOrService;
        appData.id = childSnapshot.key as any;
        appsData.push(appData);
      });
      setApps(appsData);
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  const handleDeleteApp = (id: number) => {
    setApps(apps.filter(app => app.id !== id));
  };

  return (
    <div>
      <h1>登録 / ダッシュボード</h1>
      {
        user ? (
          <Link className="blueButton" to="/app-registration">登録する</Link>
        ) : (
          <p>ログインするとサービスを登録できます</p>
        )
      }
      
      <section>
        {apps.map(app => (
          <div key={app.id}>
            <h3>{app.name}</h3>
            <img src={app.image} alt={app.name} width="200" />
            <p>{app.description}</p>
            <a href={app.link} target="_blank" rel="noopener noreferrer">Visit Link</a>
            <button onClick={() => handleDeleteApp(app.id)}>Delete</button>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Dashboard;
