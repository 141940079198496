// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Register.css (既存のファイル名がこれであることを前提としています) */

.login-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    border-radius: 5px;
    background-color: #ffffff;
}

form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

label {
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
}

input {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

button {
    font-size: 16px;
    background-color: #007BFF;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Register.css"],"names":[],"mappings":"AAAA,uDAAuD;;AAEvD;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,wCAAwC;IACxC,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* src/pages/Register.css (既存のファイル名がこれであることを前提としています) */\n\n.login-container {\n    width: 100%;\n    max-width: 400px;\n    margin: 50px auto;\n    padding: 20px;\n    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);\n    border-radius: 5px;\n    background-color: #ffffff;\n}\n\nform {\n    display: flex;\n    flex-direction: column;\n}\n\n.form-group {\n    margin-bottom: 20px;\n}\n\nlabel {\n    font-size: 16px;\n    margin-bottom: 8px;\n    display: block;\n}\n\ninput {\n    font-size: 16px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 100%;\n}\n\nbutton {\n    font-size: 16px;\n    background-color: #007BFF;\n    color: #fff;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\nbutton:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
