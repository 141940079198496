// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AppDetail from './pages/AppDetail';
import Dashboard from './pages/Dashboard';
import Pricing from './pages/Pricing';
import Support from './pages/Support';
import MenuBar from './components/MenuBar';
import Register from './pages/Register';
import Login from './pages/Login';
import AppRegistration from './pages/AppRegistration';
import { AppProvider } from './contexts/AppContext';
import Privacy from './pages/Privacy';
import Kiyaku from './pages/Kiyaku';

function App() {
  return (
    <Router>
      <MenuBar />
      <AppProvider> {/* Wrap your routes with AppProvider */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/appdetail/:appId?" element={<AppDetail />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/support" element={<Support />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/app-registration" element={<AppRegistration />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/kiyaku" element={<Kiyaku />} />
      </Routes>
      </AppProvider>
    </Router>
  );
}

export default App;